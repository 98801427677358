// 'use client';

import { m } from 'framer-motion';

// @mui
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// layouts
import CompactLayout from '@agp/ui/layouts/compact';
// assets
import { SeverErrorIllustration } from '@agp/assets/illustrations';
// components
import { RouterLink } from '@agp/ui/routes/components';
import { MotionContainer, varBounce } from '@agp/ui/components/animate';
import { Stack } from '@mui/material';
// ----------------------------------------------------------------------

export default function GeneralErrorView({showRefreshButton=true, buttons}) {
  const refreshButton = (
    <Button size="large" variant="contained" onClick={()=> window.location.reload()}>
      Refresh
    </Button>
  );
  
  return (
    <CompactLayout>
      <MotionContainer>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Something went wrong
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Our team of experts have been notified of this issue and are immediately working to resolve it.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>

        <Stack spacing={1.5} direction="row" alignItems="center" justifyContent="center" sx={{mt: 5, width:'100%'}}>
          {showRefreshButton && refreshButton}
          {buttons && buttons}
        </Stack>
      </MotionContainer>
    </CompactLayout>
  );
}
